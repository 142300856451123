const flowpage = {
  userEditsFlowpageLink: 'userEditsFlowpageLink',
  userClickFlowcodeFlowpageHompageImage: 'userClickFlowcodeFlowpageHompageImage',
  userEditsFlowpageWidget: 'userEditsFlowpageWidget',
  userClickedThirdPartyShare: 'userClickedThirdPartyShare',
  userClickedAddLinkInProfile: 'userClickedAddLinkInProfile',
  userAddsFlowpageLink: 'Add Link', //legacy
  userAddsFlowpageWidget: 'Add Widget', //legacy
  userCopiedLinkInSuccessScreen: 'userCopiedLinkInSuccessScreen',
  userCopiedLinkInSettings: 'userCopiedLinkInSettings',
  userDownloadCodeInShareModal: 'userDownloadCodeInShareModal',
  useViewedAllScreensOfShareModal: 'useViewedAllScreensOfShareModal',
  userUploadedBackgroundImage: 'userUploadedBackgroundImage',
  userChangedStyle: 'userChangedStyle',
  userClickedCustomizeTab: 'userClickedCustomizeTab',
  userDeletedFlowpage: 'userDeletedFlowpage',
  userDeactivatedWidget: 'userDeactivatedWidget',
  userDeactivatedLink: 'userDeactivatedLink',
  userDeletedWidget: 'userDeletedWidget',
  userDeletedLink: 'userDeletedLink',
  userAllowedContactShareVisible: 'userAllowedContactShareVisible',
  userReorderedLinks: 'userReorderedLinks',
  userAllowedSharePage: 'userAllowedSharePage',
  userAddsBioToPage: 'userAddsBioToPage',
  userChangedBioPicture: 'userChangedBioPicture',
  userChangedBioDisplayName: 'userChangedBioDisplayName',
  userChangedBioCaption: 'userChangedBioCaption',
  userCreatedPixel: 'userCreatedPixel',
  userUpdatedPixel: 'userUpdatedPixel',
  userTriggeredRemovePixelModal: 'userTriggeredRemovePixelModal',
  userConfirmedPixelRemoval: 'userConfirmedPixelRemove',
  userDeclinedPixelRemoval: 'userDeclinedPixelRemoval',
  userToggledPixel: 'userToggledPixel',
  userClickedSmsContactIcon: 'userClickedSmsContactIcon',
  userFocusedSlugInput: 'userFocusedSlugInput',
  userTypedInSlugInput: 'userTypedInSlugInput',
  userReceivedSlugInputError: 'userReceivedSlugInputError',
  userClickedEmailContactIcon: 'userClickedEmailContactIcon',
  userClickedLocationContactIcon: 'userClickedLocationContactIcon',
  userClickedCallContactIcon: 'userClickedCallContactIcon',
  userClickedMapDirectionsButton: 'userClickedMapDirectionsButton',
  userTypedInAuthFormEmailField: 'userTypedInAuthFormEmailField',
  userTypedInAuthFormPasswordField: 'userTypedInAuthFormPasswordField',
  userClickedAuthFormAuthenticateWithSocial: 'userClickedAuthFormAuthenticateWithSocial',
  userClickedAuthFormForgotPassword: 'userClickedAuthFormForgotPassword',
  userClickedAuthFormSubmitButton: 'userClickedAuthFormSubmitButton',
  userScrolledPastFirstFullScreen: 'userScrolledPastFirstFullScreen',
  userDownloadsInvalidCollectionCSVExport: 'Invalid CSV Download Click', //legacy
  userDownloadsInvalidCollectionGoogleSheet: 'Invalid Google Sheet Click', //legacy
  userDownloadsCollectionCSV: 'CSV Download Click', //legacy
  userDownloadsCollectionGoogleSheet: 'Google Sheet Click', //legacy
  userCheckedAuthFormIAgreeToTermsAndConditions: 'userCheckedAuthFormIAgreeToTermsAndConditions:',
  userClickedAuthFormTermsAndConditionsLink: 'userClickedAuthFormTermsAndConditionsLink',
  userClickedContentTab: 'userClickedContentTab',
  userClickedSettingsTab: 'userClickedSettingsTab',
  userToggledRemoveBranding: 'userToggledRemoveBranding',
  userClickedWhoIsUsingFlowpageExample: 'userClickedWhoIsUsingFlowpageExample',
  userClickedAddBioModal: 'userClickedAddBioModal',
  userClickedAddContactInfoModal: 'userClickedAddContactInfoModal',
  userScrolledToHomepageSection: 'userScrolledToHomepageSection',
  userClickedAddFlowpageLinkType: 'userClickedAddFlowpageLinkType',
  userClickedAddFlowpageSectionType: 'userClickedAddFlowpageSectionType',
  userClickedGatedFlowpageColorOptions: 'userClickedGatedFlowpageColorOptions',
  userClickedGatedFlowpageFontOptions: 'userClickedGatedFlowpageFontOptions',
  userClickedGatedDuplicateFlowpage: 'userClickedGatedDuplicateFlowpage',
  userClickedGatedCRMIntegrations: 'userClickedGatedCRMIntegrations',
  userClickedGatedCRMTerms: 'userClickedGatedCRMTerms',
  userChangedTabNavigationSettings: 'userChangedTabNavigationSettings',
  userClickedEmailCodeButton: 'userClickedEmailCodeButton',
  userClickedCompleteStripe: 'Flowpage_stripe_commerce_signup_user_clicked_complete_signup',
  userClickedManageFlowpageStripeSuccess:
    'Flowpage_stripe_commerce_signup_user_clicked_manage_page',
  userClickedStripeCommerceLink: 'Flowpage_stripe_commerce_user_clicked_add_link',
  userCompletedStripeCommerceCheckout: 'Flowpage_stripe_commerce_checkout_user_completed',
  userClickedConnectWithStripe: 'Flowpage_stripe_commerce_user_clicked_connect',
  Flowpage_core_management_user_hit_page_limit: 'Flowpage_core_management_user_hit_page_limit',
  Flowpage_Homepage_Clicked_WatchDemo_Button: 'Flowpage_Homepage_Clicked_WatchDemo_Button',
  Flowpage_Homepage_Clicked_GetStarted_Button: 'Flowpage_Homepage_Clicked_GetStarted_Button',
  Flowpage_Settings_Clicked_Gated_DeindexFlowpage_Toggle:
    'Flowpage_Settings_Clicked_Gated_DeindexFlowpage_Toggle',
  Flowpage_Settings_Clicked_DeindexFlowpage_Toggle:
    'Flowpage_Settings_Clicked_DeindexFlowpage_Toggle',
  Flowpage_Preview_Generator_Clicked_Flowpage_Preview:
    'Flowpage_Preview_Generator_Clicked_Flowpage_Preview',
  Flowpage_Preview_Generator_Clicked_Color: 'Flowpage_Preview_Generator_Clicked_Flowpage_Preview',
  Flowpage_Preview_Generator_Clicked_SignUp: 'Flowpage_Preview_Generator_Clicked_SignUp',
  Flowpage_Preview_Generator_Entered_PageName: 'Flowpage_Preview_Generator_Entered_PageName',
  Flowpage_Preview_Generator_Entered_Destination_Input:
    'Flowpage_Preview_Generator_Entered_Destination_Input',
  Flowpage_Preview_Generator_Entered_SignUpEmailAddress:
    'Flowpage_Preview_Generator_Entered_SignUpEmailAddress',
  Flowpage_Preview_Generator_Entered_SignUpPassword:
    'Flowpage_Preview_Generator_Entered_SignUpPassword',
  Flowpage_Preview_Generator_Scrolled_ToGenerator:
    'Flowpage_Preview_Generator_Scrolled_ToGenerator',
  Flowpage_PageProfile_Exited_ReminderBanner: 'Flowpage_PageProfile_Exited_ReminderBanner',
  Flowpage_PageProfile_Clicked_EditPageProfileIcon:
    'Flowpage_PageProfile_Clicked_EditPageProfileIcon',
  Flowpage_PageProfile_Clicked_EditBioImageButton:
    'Flowpage_PageProfile_Clicked_EditBioImageButton',
  Flowpage_PageProfile_Clicked_RemoveBioImageButton:
    'Flowpage_PageProfile_Clicked_RemoveBioImageButton',
  Flowpage_PageProfile_Clicked_AddBioImageButton: 'Flowpage_PageProfile_Clicked_AddBioImageButton',
  Flowpage_PageProfile_Typed_Name: 'Flowpage_PageProfile_Typed_Name',
  Flowpage_PageProfile_Typed_Bio: 'Flowpage_PageProfile_Typed_Bio',
  Flowpage_PageProfile_Clicked_PreviewFlowpageButton:
    'Flowpage_PageProfile_Clicked_PreviewFlowpageButton',
  Flowpage_PageProfile_Selected_Color: 'Flowpage_PageProfile_Selected_Color',
  Flowpage_PageProfile_Selected_Font: 'Flowpage_PageProfile_Selected_Font',
  Flowpage_PageProfile_Selected_Shape: 'Flowpage_PageProfile_Selected_Shape',
  Flowpage_PageProfile_Selected_LinkStyle: 'Flowpage_PageProfile_Selected_LinkStyle',
  Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton:
    'Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton',
  Flowpage_PageProfile_Selected_UploadBackgroundImageButton:
    'Flowpage_PageProfile_Selected_UploadBackgroundImageButton',
  Flowpage_PageProfile_Added_BackgroundImage: 'Flowpage_PageProfile_Added_BackgroundImage',
  Flowpage_PageProfileAnalytics_Hovered_HelpIcon: 'Flowpage_PageProfileAnalytics_Hovered_HelpIcon',
  Flowpage_PageProfile_Clicked_LeftNavigationBox: 'Flowpage_PageProfile_Clicked_LeftNavigationBox',
  Flowpage_PageProfile_Copied_FlowpageURL: 'Flowpage_PageProfile_Copied_FlowpageURL',
  Flowpage_PageProfile_Exited_GatedFeatureUpsell: 'Flowpage_PageProfile_Exited_GatedFeatureUpsell',
  Flowpage_PageProfile_Clicked_ContextMenuButton: 'Flowpage_PageProfile_Clicked_ContextMenuButton',
  Flowpage_Permissions_Clicked_Desktop_Tab: 'Flowpage_Permissions_Clicked_Desktop_Tab',
  Flowpage_PageManagement_Clicked_PermissionsButton:
    'Flowpage_PageManagement_Clicked_PermissionsButton',
  Flowpage_PageManagement_Clicked_CreatePageButton:
    'Flowpage_PageManagement_Clicked_CreatePageButton',
  Flowpage_PageManagement_Clicked_WatchDemoButton:
    'Flowpage_PageManagement_Clicked_WatchDemoButton',
  Flowpage_PageManagement_Clicked_CloseWatchDemoButton:
    'Flowpage_PageManagement_Clicked_CloseWatchDemoButton',
  Flowpage_Mobile_Onboarding_Clicked_NextButton: 'Flowpage_Mobile_Onboarding_Clicked_NextButton',
  Flowpage_Mobile_Onboarding_Clicked_PreviewButton:
    'Flowpage_Mobile_Onboarding_Clicked_PreviewButton',
  Flowpage_PageProfile_Clicked_ProUpsellHexColors:
    'Flowpage_PageProfile_Clicked_ProUpsellHexColors',
  Flowpage_Mobile_Onboarding_PublishedPage: 'Flowpage_Mobile_Onboarding_PublishedPage',
  Flowpage_Mobile_Onboarding_Added_Destination: 'Flowpage_Mobile_Onboarding_Added_Destination',
  Flowpage_Mobile_Onboarding_Entered_Email: 'Flowpage_Mobile_Onboarding_Entered_Email',
  Flowpage_Mobile_Onboarding_Entered_Password: 'Flowpage_Mobile_Onboarding_Entered_Password',
  Flowpage_Mobile_Onboarding_Clicked_View_Flowpage:
    'Flowpage_Mobile_Onboarding_Clicked_View_Flowpage',
  Flowcode_PageManagement_Clicked_Flowpage_FAQs_Btn:
    'Flowcode_PageManagement_Clicked_Flowpage_FAQs_Btn',
  Flowcode_PageProfile_Clicked_Font_Style_Lock: 'Flowcode_PageProfile_Clicked_Font_Style_Lock',
  Flowpage_PageProfile_Clicked_Link_Level_Customization_Options:
    'Flowpage_PageProfile_Clicked_Link_Level_Customization_Options',
  Flowpage_PageProfile_Toggled_Link_Level_Advanced_Customization:
    'Flowpage_PageProfile_Toggled_Link_Level_Advanced_Customization',
  Flowpage_PageProfile_Selected_Link_Level_Link_Color:
    'Flowpage_PageProfile_Selected_Link_Level_Link_Color',
  Flowpage_PageProfile_Selected_Link_Level_Shadow_Color:
    'Flowpage_PageProfile_Selected_Link_Level_Shadow_Color',
  Flowpage_PageProfile_Selected_Link_Level_Border_Color:
    'Flowpage_PageProfile_Selected_Link_Level_Border_Color',
  Flowpage_PageProfile_Selected_Link_Level_Text_Size:
    'Flowpage_PageProfile_Selected_Link_Level_Text_Size',
  Flowpage_PageProfile_Selected_Link_Level_Font_Color:
    'Flowpage_PageProfile_Selected_Link_Level_Font_Color',
  Flowpage_PageProfile_Clicked_Page_Level_Link_Colors_Lock:
    'Flowpage_PageProfile_Clicked_Page_Level_Link_Colors_Lock',
  Flowpage_PageProfile_Selected_Page_Level_Font_Color:
    'Flowpage_PageProfile_Selected_Page_Level_Font_Color',
  Flowpage_PageProfile_Selected_Page_Level_Text_Size:
    'Flowpage_PageProfile_Selected_Page_Level_Text_Size',
  Flowpage_PageProfile_Selected_Page_Level_Link_Color:
    'Flowpage_PageProfile_Selected_Page_Level_Link_Color',
  Flowpage_PageProfile_Selected_Page_Level_Border_Color:
    'Flowpage_PageProfile_Selected_Page_Level_Border_Color',
  Flowpage_PageProfile_Selected_Page_Level_Shadow_Color:
    'Flowpage_PageProfile_Selected_Page_Level_Shadow_Color',
  Flowpage_PageProfile_Clicked_AddLink: 'Flowpage_PageProfile_Clicked_AddLink',
  Flowpage_PageProfile_Clicked_ExploreLinks: 'Flowpage_PageProfile_Clicked_ExploreLinks',
  Flowpage_PageProfile_Clicked_Permissions: 'Flowpage_PageProfile_Clicked_Permissions',
  Flowpage_PageProfile_Clicked_Duplicate: ' Flowpage_PageProfile_Clicked_Duplicate',
  Flowpage_PageProfile_Clicked_Analytics: 'Flowpage_PageProfile_Clicked_Analytics',
  Flowpage_PageProfile_Clicked_Share: 'Flowpage_PageProfile_Clicked_Share',
  Flowpage_PageProfile_ExploreLinksModal_Closed_Modal:
    'Flowpage_PageProfile_ExploreLinksModal_Closed_Modal',
  Flowpage_PageProfile_ExploreLinksModal_Clicked_Dismiss_MostPopularLinksSection:
    'Flowpage_PageProfile_ExploreLinksModal_Clicked_Dismiss_MostPopularLinksSection',
  Flowpage_PageProfile_ExploreLinksModal_Clicked_AddLink:
    'Flowpage_PageProfile_ExploreLinksModal_Clicked_AddLink',
  Flowpage_PageProfile_DuplicateOverlay_Closed_Overlay:
    'Flowpage_PageProfile_DuplicateOverlay_Closed_Overlay',
  Flowpage_PageProfile_DuplicateOverlay_Changed_DuplicateCount:
    'Flowpage_PageProfile_DuplicateOverlay_Changed_DuplicateCount',
  Flowpage_PageProfile_DuplicateOverlay_Clicked_MakeDuplicate:
    'Flowpage_PageProfile_DuplicateOverlay_Clicked_MakeDuplicate',
  Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToDuplicatePage:
    'Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToDuplicatePage',
  Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToPages:
    'Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToPages',
  Flowpage_PageProfile_DuplicateOverlay_Clicked_DuplicateMore:
    'Flowpage_PageProfile_DuplicateOverlay_Clicked_DuplicateMore',
  Flowpage_PageProfile_Clicked_CancelLinkEdits: 'Flowpage_PageProfile_Clicked_CancelLinkEdits',
  Flowpage_PageProfile_Clicked_HideLink: 'Flowpage_PageProfile_Clicked_HideLink',
  Flowpage_PageProfile_StandardLinkForm_Typed_LinkUrl:
    'Flowpage_PageProfile_StandardLinkForm_Typed_LinkUrl',
  Flowpage_Mobile_Onboarding_ContactCollection_Added:
    'Flowpage_Mobile_Onboarding_ContactCollection_Added',
  Flowpage_Mobile_Onboarding_Toggled_Include_CRM: 'Flowpage_Mobile_Onboarding_Toggled_Include_CRM',
  Flowpage_Clicked_Suggested_Link_URL: 'Flowpage_Clicked_Suggested_Link_URL',
  Flowpage_PageProfile_Clicked_Image_Display_Type:
    'Flowpage_PageProfile_Clicked_Image_Display_Type',
  Flowpage_PageProfile_Typed_Link_Title: 'Flowpage_PageProfile_Typed_Link_Title',
  Flowpage_PageProfile_Typed_Link_Description: 'Flowpage_PageProfile_Typed_Link_Description',
  Flowpage_PageProfile_Clicked_Add_Icon_Image: 'Flowpage_PageProfile_Clicked_Add_Icon_Image',
  Flowpage_PageProfile_Clicked_Icon_Image_Reposition:
    'Flowpage_PageProfile_Clicked_Icon_Image_Reposition',
  Flowpage_PageProfile_Clicked_Icon_Image_Zoom: 'Flowpage_PageProfile_Clicked_Icon_Image_Zoom',
  Flowpage_PageProfile_Clicked_Change_Icon_Image: 'Flowpage_PageProfile_Clicked_Change_Icon_Image',
  Flowpage_PageProfile_Clicked_Save_Icon_Image: 'Flowpage_PageProfile_Clicked_Save_Icon_Image',
  Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window:
    'Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window',
  Flowpage_PageProfile_Clicked_Replace_Icon_Image:
    'Flowpage_PageProfile_Clicked_Replace_Icon_Image',
  Flowpage_PageProfile_Clicked_Delete_Icon_Image: 'Flowpage_PageProfile_Clicked_Delete_Icon_Image',
  Flowpage_PageProfile_Selected_Link_Level_Fill_Color:
    'Flowpage_PageProfile_Selected_Link_Level_Fill_Color',
  Flowpage_PageProfile_Clicked_Edit_Link: 'Flowpage_PageProfile_Clicked_Edit_Link',
  Flowpage_PageProfile_Clicked_Delete_Link: 'Flowpage_PageProfile_Clicked_Delete_Link',
  Flowpage_PageProfile_Clicked_Cancel_Delete_Link:
    'Flowpage_PageProfile_Clicked_Cancel_Delete_Link',
  Flowpage_PageProfile_Clicked_Profile_Image_Reposition:
    'Flowpage_PageProfile_Clicked_Profile_Image_Reposition',
  Flowpage_PageProfile_Clicked_Profile_Image_Zoom:
    'Flowpage_PageProfile_Clicked_Profile_Image_Zoom',
  Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape:
    'Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape',
  Flowpage_PageProfile_Clicked_Delete_Profile_Image:
    'Flowpage_PageProfile_Clicked_Delete_Profile_Image',
  Flowpage_PageProfile_Clicked_Change_Profile_Image:
    'Flowpage_PageProfile_Clicked_Change_Profile_Image',
  Flowpage_PageProfile_Clicked_Save_Profile_Image:
    'Flowpage_PageProfile_Clicked_Save_Profile_Image',
  Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window:
    'Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window',
  Flowpage_Management_Clicked_Create_Page_button: 'Flowpage_Management_Clicked_Create_Page_button',
  Flowpage_Management_Clicked_Sort_Option: 'Flowpage_Management_Clicked_Sort_Option',
  Flowpage_Management_Clicked_Sort_Button: 'Flowpage_Management_Clicked_Sort_Button',
  Flowpage_Management_Clicked_Grid_List_Toggle: 'Flowpage_Management_Clicked_Grid_List_Toggle',
  Flowpage_Management_Clicked_Sort_Direction_Button:
    'Flowpage_Management_Clicked_Sort_Direction_Button',
  Flowpage_PageProfile_Clicked_Reset_LinkLevelStyling:
    'Flowpage_PageProfile_Clicked_Reset_LinkLevelStyling',
  Flowpage_PageProfile_Clicked_Publish_Flowpage: 'Flowpage_PageProfile_Clicked_Publish_Flowpage',
  Flowpage_TemplateEditor_Clicked_Assign: 'Flowpage_TemplateEditor_Clicked_Assign',
  Flowpage_Management_Clicked_ChangeSubdomain: 'Flowpage_Management_Clicked_ChangeSubdomain',
  Flowpage_TemplateEditor_Clicked_PreviewFlowpageButton:
    'Flowpage_TemplateEditor_Clicked_PreviewFlowpageButton',
  Flowpage_Management_Typed_Slugname: 'Flowpage_Management_Typed_Slugname',
  Flowpage_Management_Clicked_Get_Started_Button: 'Flowpage_Management_Clicked_Get_Started_Button',
  Flowpage_Management_Clicked_Share_Modal_Flowpage_Link:
    'Flowpage_Management_Clicked_Share_Modal_Flowpage_Link',
  Flowpage_Management_Clicked_Share_Modal_Copy_Url_Button:
    'Flowpage_Management_Clicked_Share_Modal_Copy_Url_Button',
  Flowpage_Management_Clicked_Share_Modal_Start_Connecting_Button:
    'Flowpage_Management_Clicked_Share_Modal_Start_Connecting_Button',
  Flowpage_Management_Clicked_Share_Modal_Flowtag_Button:
    'Flowpage_Management_Clicked_Share_Modal_Flowtag_Button',
  Flowpage_Management_Clicked_Share_Modal_View_Flowcode_Button:
    'Flowpage_Management_Clicked_Share_Modal_View_Flowcode_Button',
  Flowpage_Homepage_Clicked_Create_Flowcode_Button:
    'Flowpage_Homepage_Clicked_Create_Flowcode_Button',
  Flowpage_PageProfile_RichTextEditor_Clicked_FormattingOption:
    'Flowpage_PageProfile_RichTextEditor_Clicked_FormattingOption',
  FlowpageManagement_ShareAssetsModal_Shared_Flowpage:
    'FlowpageManagement_ShareAssetsModal_Shared_Flowpage',
  Flowpage_PageManagement_Clicked_CreateTemplateButton:
    'Flowpage_PageManagement_Clicked_CreateTemplateButton',
  Flowpage_Settings_Hovered_PixelTooltip_passthrough:
    'Flowpage_Settings_Hovered_PixelTooltip_passthrough',
  Flowpage_Settings_Clicked_PixelToggle_passthrough:
    'Flowpage_Settings_Clicked_PixelToggle_passthrough'
} as const

export default flowpage
