import { Domains } from '@dtx-company/true-common/src/types/domains'
import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/assetTagging'

const {
  Global_AssetTagging_Selected_Pill,
  Global_AssetTagging_Unselected_Pill,
  Global_AssetTagging_Typed_Tag,
  Global_AssetTagging_Clicked_SaveTagButton,
  Global_AssetTagging_Clicked_CreateNewTagButton,
  Global_AssetTagging_Hovered_HelpIcon
} = eventNames

export const getProduct = (isFP: boolean): string => (isFP ? Domains.FLOWPAGE : Domains.FLOWCODE)

const e = {
  Global_AssetTagging_Selected_Pill(isFlowpage: boolean, label: string): void {
    fireHeapEvent(Global_AssetTagging_Selected_Pill, {
      label,
      product: getProduct(isFlowpage)
    })
  },
  Global_AssetTagging_Unselected_Pill(isFlowpage: boolean, label: string): void {
    fireHeapEvent(Global_AssetTagging_Unselected_Pill, {
      label,
      product: getProduct(isFlowpage)
    })
  },
  Global_AssetTagging_Typed_Tag(isFlowpage: boolean): void {
    fireHeapEvent(Global_AssetTagging_Typed_Tag, {
      product: getProduct(isFlowpage)
    })
  },
  Global_AssetTagging_Clicked_SaveTagButton(isFlowpage: boolean): void {
    fireHeapEvent(Global_AssetTagging_Clicked_SaveTagButton, {
      product: getProduct(isFlowpage)
    })
  },
  Global_AssetTagging_Clicked_CreateNewTagButton(isFlowpage: boolean): void {
    fireHeapEvent(Global_AssetTagging_Clicked_CreateNewTagButton, {
      product: getProduct(isFlowpage)
    })
  },
  Global_AssetTagging_Hovered_HelpIcon(isFlowpage: boolean, label: string): void {
    fireHeapEvent(Global_AssetTagging_Hovered_HelpIcon, {
      displaytext: label,
      iconSubject: 'Tagging',
      product: getProduct(isFlowpage)
    })
  }
}

export default e
