import { AddLinkType } from '@app/page/src/components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.constants'
import { AnalyticsIntegrations, fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { CRMLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import { CheckoutSession } from '@app/page/src/components/commerce/checkoutSuccess/CheckoutSuccess.types'
import { HeapEventProperties } from '../types'
import { SelectedFlowpageView } from '@dtx-company/inter-app/src/types/flowpage'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import { fireHeapEvent, getHeapBooleanParam } from '../helpers'
import { getProduct } from '../events/assetTagging'
import eventNames from '../constants/flowpage'

const flowpage = {
  userEditsFlowpageLink(properties: { link_id: string }): void {
    const { userEditsFlowpageLink } = eventNames
    fireHeapEvent(userEditsFlowpageLink, properties)
  },
  userClickedThirdPartyShare(properties: { platform: string }): void {
    const { userClickedThirdPartyShare } = eventNames
    fireHeapEvent(userClickedThirdPartyShare, properties)
  },
  userEditsFlowpageWidget(properties: { link_id: string }): void {
    const { userEditsFlowpageWidget } = eventNames
    fireHeapEvent(userEditsFlowpageWidget, properties)
  },
  userAddsFlowpageWidget(properties: { link_id: string }): void {
    const { userAddsFlowpageWidget } = eventNames
    fireHeapEvent(userAddsFlowpageWidget, properties)
  },

  userToggledRemoveBranding(properties: { page_id: string }): void {
    const { userToggledRemoveBranding } = eventNames

    fireHeapEvent(userToggledRemoveBranding, properties)
  },
  userCopiedLinkInSettings({ page_id }: { page_id: string }): void {
    const { userCopiedLinkInSettings } = eventNames
    fireHeapEvent(userCopiedLinkInSettings, { page_id })
  },
  userCopiedLinkInSuccessScreen({ page_id }: { page_id: string }): void {
    const { userCopiedLinkInSuccessScreen } = eventNames
    fireHeapEvent(userCopiedLinkInSuccessScreen, { page_id })
  },
  userDownloadCodeInShareModal(): void {
    const { userDownloadCodeInShareModal } = eventNames
    fireHeapEvent(userDownloadCodeInShareModal)
  },
  useViewedAllScreensOfShareModal(): void {
    const { useViewedAllScreensOfShareModal } = eventNames
    fireHeapEvent(useViewedAllScreensOfShareModal)
  },
  userUploadedBackgroundImage(): void {
    const { userUploadedBackgroundImage } = eventNames
    fireHeapEvent(userUploadedBackgroundImage)
  },
  userChangedStyle(): void {
    const { userChangedStyle } = eventNames
    fireHeapEvent(userChangedStyle)
  },
  userClickedCustomizeTab(): void {
    const { userClickedCustomizeTab } = eventNames
    fireHeapEvent(userClickedCustomizeTab)
  },
  userDeletedFlowpage(): void {
    const { userDeletedFlowpage } = eventNames
    fireHeapEvent(userDeletedFlowpage)
  },
  userDeactivatedWidget({ link_id }: { link_id: string }): void {
    const { userDeactivatedWidget } = eventNames
    fireHeapEvent(userDeactivatedWidget, { link_id })
  },
  userDeactivatedLink({ link_id }: { link_id: string }): void {
    const { userDeactivatedLink } = eventNames
    fireHeapEvent(userDeactivatedLink, { link_id })
  },
  userDeletedWidget({ link_id }: { link_id: string }): void {
    const { userDeletedWidget } = eventNames
    fireHeapEvent(userDeletedWidget, { link_id })
  },
  userDeletedLink({ link_id }: { link_id: string }): void {
    const { userDeletedLink } = eventNames
    fireHeapEvent(userDeletedLink, { link_id })
  },
  userAllowedContactShareVisible(): void {
    const { userAllowedContactShareVisible } = eventNames
    fireHeapEvent(userAllowedContactShareVisible)
  },
  userReorderedLinks({ page_id }: { page_id: string }): void {
    const { userReorderedLinks } = eventNames
    fireHeapEvent(userReorderedLinks, { page_id })
  },
  userAllowedSharePage({ page_id }: { page_id: string }): void {
    const { userAllowedSharePage } = eventNames
    fireHeapEvent(userAllowedSharePage, { page_id })
  },
  userAddsBioToPage({ page_id }: { page_id: string }): void {
    const { userAddsBioToPage } = eventNames
    fireHeapEvent(userAddsBioToPage, { page_id })
  },
  userChangedBioPicture({ page_id }: { page_id: string }): void {
    const { userChangedBioPicture } = eventNames
    fireHeapEvent(userChangedBioPicture, { page_id })
  },
  userChangedBioDisplayName({ page_id }: { page_id: string }): void {
    const { userChangedBioDisplayName } = eventNames
    fireHeapEvent(userChangedBioDisplayName, { page_id })
  },
  userChangedBioCaption({ page_id }: { page_id: string }): void {
    const { userChangedBioCaption } = eventNames
    fireHeapEvent(userChangedBioCaption, { page_id })
  },
  userCreatedPixel(properties: { pixelId: string }): void {
    const { userCreatedPixel } = eventNames
    fireHeapEvent(userCreatedPixel, properties)
  },
  userUpdatedPixel(properties: { pixelId: string }): void {
    const { userUpdatedPixel } = eventNames
    fireHeapEvent(userUpdatedPixel, properties)
  },
  userTriggeredRemovePixelModal(): void {
    const { userTriggeredRemovePixelModal } = eventNames
    fireHeapEvent(userTriggeredRemovePixelModal)
  },
  userConfirmedPixelRemoval(): void {
    const { userConfirmedPixelRemoval } = eventNames
    fireHeapEvent(userConfirmedPixelRemoval)
  },
  userDeclinedPixelRemoval(): void {
    const { userDeclinedPixelRemoval } = eventNames
    fireHeapEvent(userDeclinedPixelRemoval)
  },
  userToggledPixel(properties: { pixelId: string }): void {
    const { userToggledPixel } = eventNames
    fireHeapEvent(userToggledPixel, properties)
  },

  userClickedContactInfoIcon({ type, linkId }: { type: string; linkId: string }): void {
    const {
      userClickedSmsContactIcon,
      userClickedEmailCodeButton,
      userClickedCallContactIcon,
      userClickedLocationContactIcon
    } = eventNames
    switch (type) {
      case 'email':
        return fireHeapEvent(userClickedEmailCodeButton, { link_id: linkId })
      case 'location':
        return fireHeapEvent(userClickedLocationContactIcon, { link_id: linkId })
      case 'text':
        return fireHeapEvent(userClickedSmsContactIcon, { link_id: linkId })
      case 'call':
        return fireHeapEvent(userClickedCallContactIcon, { link_id: linkId })
      default:
        return
    }
  },
  userClickedMapDirectionsButton(): void {
    const { userClickedMapDirectionsButton } = eventNames
    fireHeapEvent(userClickedMapDirectionsButton)
  },
  userTypedInAuthFormEmailField(): void {
    const { userTypedInAuthFormEmailField } = eventNames
    fireHeapEvent(userTypedInAuthFormEmailField)
  },
  userTypedInAuthFormPasswordField(): void {
    const { userTypedInAuthFormPasswordField } = eventNames
    fireHeapEvent(userTypedInAuthFormPasswordField)
  },
  userClickedAuthFormAuthenticateWithSocial(socialProvider: string): void {
    fireHeapEvent(eventNames.userClickedAuthFormAuthenticateWithSocial, { socialProvider })
  },
  userClickedAuthFormForgotPassword(): void {
    fireHeapEvent(eventNames.userClickedAuthFormForgotPassword)
  },
  userClickedAuthFormSubmitButton(properties: HeapEventProperties): void {
    fireHeapEvent(eventNames.userClickedAuthFormSubmitButton, properties)
  },
  userCheckedAuthFormIAgreeToTermsAndConditions(): void {
    fireHeapEvent(eventNames.userCheckedAuthFormIAgreeToTermsAndConditions)
  },
  userClickedAuthFormTermsAndConditionsLink(): void {
    fireHeapEvent(eventNames.userClickedAuthFormTermsAndConditionsLink)
  },
  userFocusedSlugInput(pageLocation: string): void {
    const { userFocusedSlugInput } = eventNames
    fireHeapEvent(userFocusedSlugInput, { pageLocation })
  },
  userTypedInSlugInput(pageLocation: string): void {
    const { userTypedInSlugInput } = eventNames
    fireHeapEvent(userTypedInSlugInput, { pageLocation })
  },
  userReceivedSlugInputError(pageLocation: string, errorType: string): void {
    const { userReceivedSlugInputError } = eventNames
    fireHeapEvent(userReceivedSlugInputError, { pageLocation, errorType })
  },
  userScrolledPastFirstFullScreen(): void {
    fireHeapEvent(eventNames.userScrolledPastFirstFullScreen)
  },
  userDownloadsInvalidCollectionCSVExport(properties: { link_id: string }): void {
    const { userDownloadsInvalidCollectionCSVExport } = eventNames
    fireHeapEvent(userDownloadsInvalidCollectionCSVExport, properties)
  },
  userDownloadsInvalidCollectionGoogleSheet(properties: { link_id: string }): void {
    const { userDownloadsInvalidCollectionGoogleSheet } = eventNames
    fireHeapEvent(userDownloadsInvalidCollectionGoogleSheet, properties)
  },
  userDownloadsCollectionCSV(properties: { link_id: string }): void {
    const { userDownloadsCollectionCSV } = eventNames
    fireHeapEvent(userDownloadsCollectionCSV, properties)
  },
  userDownloadsCollectionGoogleSheet(properties: { link_id: string }): void {
    const { userDownloadsCollectionGoogleSheet } = eventNames
    fireHeapEvent(userDownloadsCollectionGoogleSheet, properties)
  },
  userClickedContentTab(): void {
    const { userClickedContentTab } = eventNames
    fireHeapEvent(userClickedContentTab)
  },
  userClickedSettingsTab(): void {
    const { userClickedSettingsTab } = eventNames
    fireHeapEvent(userClickedSettingsTab)
  },
  userClickFlowcodeFlowpageHompageImage(properties: { page_slug: string }): void {
    const { userClickFlowcodeFlowpageHompageImage } = eventNames
    fireHeapEvent(userClickFlowcodeFlowpageHompageImage, properties)
  },
  userClickedWhoIsUsingFlowpageExample(properties: { page_slug: string }): void {
    const { userClickedWhoIsUsingFlowpageExample } = eventNames
    fireHeapEvent(userClickedWhoIsUsingFlowpageExample, properties)
  },
  userScrolledToHomepageSection(properties: { section_name: string }): void {
    const { userScrolledToHomepageSection } = eventNames
    fireHeapEvent(userScrolledToHomepageSection, properties)
  },
  userClickedAddBioModal(): void {
    const { userClickedAddBioModal } = eventNames
    fireHeapEvent(userClickedAddBioModal)
  },
  userClickedAddContactInfoModal(): void {
    const { userClickedAddContactInfoModal } = eventNames
    fireHeapEvent(userClickedAddContactInfoModal)
  },
  userClickedAddFlowpageLinkType(properties: { pageLinkType: AddLinkType }): void {
    const { userClickedAddFlowpageLinkType } = eventNames
    fireHeapEvent(userClickedAddFlowpageLinkType, properties)
  },
  userClickedAddFlowpageSectionType(properties: { pageSectionType: AddLinkType }): void {
    const { userClickedAddFlowpageSectionType } = eventNames
    fireHeapEvent(userClickedAddFlowpageSectionType, properties)
  },
  userClickedGatedFlowpageColorOptions(): void {
    const { userClickedGatedFlowpageColorOptions } = eventNames
    fireHeapEvent(userClickedGatedFlowpageColorOptions)
  },
  userClickedGatedFlowpageFontOptions(): void {
    const { userClickedGatedFlowpageFontOptions } = eventNames
    fireHeapEvent(userClickedGatedFlowpageFontOptions)
  },
  userClickedGatedDuplicateFlowpage(): void {
    const { userClickedGatedDuplicateFlowpage } = eventNames
    fireHeapEvent(userClickedGatedDuplicateFlowpage)
  },
  userClickedGatedCRMIntegrations({ variant }: { variant?: CRMLabels }): void {
    const { userClickedGatedCRMIntegrations } = eventNames
    fireHeapEvent(userClickedGatedCRMIntegrations, { variant })
  },
  userClickedGatedCRMTerms(): void {
    const { userClickedGatedCRMTerms } = eventNames
    fireHeapEvent(userClickedGatedCRMTerms)
  },
  userChangedTabNavigationSettings({ pageId }: { pageId: string }): void {
    const { userChangedTabNavigationSettings } = eventNames
    fireHeapEvent(userChangedTabNavigationSettings, { pageId })
  },
  userClickedManageFlowpageStripeSuccess(): void {
    const { userClickedManageFlowpageStripeSuccess } = eventNames
    fireHeapEvent(userClickedManageFlowpageStripeSuccess)
  },
  userClickedCompleteStripe(): void {
    const { userClickedCompleteStripe } = eventNames
    fireHeapEvent(userClickedCompleteStripe)
  },
  userClickedAddStripeCommerceLink(): void {
    const { userClickedStripeCommerceLink } = eventNames
    fireHeapEvent(userClickedStripeCommerceLink)
  },
  userCompletedStripeCommerceCheckout(checkoutData: CheckoutSession, pageId: string): void {
    const { userCompletedStripeCommerceCheckout } = eventNames
    fireHeapEvent(userCompletedStripeCommerceCheckout, { checkoutData, pageId })
  },
  userClickedConnectWithStripe(pageId: string): void {
    const { userClickedConnectWithStripe } = eventNames
    fireHeapEvent(userClickedConnectWithStripe, { pageId })
  },
  Flowpage_core_management_user_hit_page_limit({
    upsellPlan,
    pageLimit,
    sourceId
  }: {
    upsellPlan?: string
    sourceId?: string
    pageLimit?: number
  }): void {
    fireHeapEvent(eventNames.Flowpage_core_management_user_hit_page_limit, {
      upsellPlan,
      pageLimit,
      sourceId
    })
  },
  Flowpage_Homepage_Clicked_WatchDemo_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Homepage_Clicked_WatchDemo_Button)
  },
  Flowpage_Homepage_Clicked_GetStarted_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Homepage_Clicked_GetStarted_Button)
  },
  Flowpage_Settings_Clicked_Gated_DeindexFlowpage_Toggle(): void {
    fireHeapEvent(eventNames.Flowpage_Settings_Clicked_Gated_DeindexFlowpage_Toggle)
  },
  Flowpage_Settings_Clicked_DeindexFlowpage_Toggle(pageId: string, isIndexed: boolean): void {
    fireHeapEvent(eventNames.Flowpage_Settings_Clicked_DeindexFlowpage_Toggle, {
      pageId,
      isIndexed
    })
  },
  Flowpage_Preview_Generator_Clicked_Flowpage_Preview(): void {
    const { Flowpage_Preview_Generator_Clicked_Flowpage_Preview } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Clicked_Flowpage_Preview)
  },
  Flowpage_Preview_Generator_Clicked_Color(args: { color: string }): void {
    const { Flowpage_Preview_Generator_Clicked_Color } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Clicked_Color, { color: args.color })
  },
  Flowpage_Preview_Generator_Clicked_SignUp(args: { signUpType: SocialProvider }): void {
    const { Flowpage_Preview_Generator_Clicked_SignUp } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Clicked_SignUp, { color: args.signUpType })
  },
  Flowpage_Preview_Generator_Entered_PageName(): void {
    const { Flowpage_Preview_Generator_Entered_PageName } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Entered_PageName)
  },
  Flowpage_Preview_Generator_Entered_Destination_Input(args: { destination: string }): void {
    const { Flowpage_Preview_Generator_Entered_Destination_Input } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Entered_Destination_Input, {
      destination: args.destination
    })
  },
  Flowpage_Preview_Generator_Entered_SignUpEmailAddress(): void {
    const { Flowpage_Preview_Generator_Entered_SignUpEmailAddress } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Entered_SignUpEmailAddress)
  },
  Flowpage_Preview_Generator_Entered_SignUpPassword(): void {
    const { Flowpage_Preview_Generator_Entered_SignUpPassword } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Entered_SignUpPassword)
  },
  Flowpage_Preview_Generator_Scrolled_ToGenerator(): void {
    const { Flowpage_Preview_Generator_Scrolled_ToGenerator } = eventNames
    fireHeapEvent(Flowpage_Preview_Generator_Scrolled_ToGenerator)
  },
  Flowpage_PageProfile_Exited_ReminderBanner({
    bannerColor,
    bannerCta
  }: {
    bannerColor: string
    bannerCta: string
  }): void {
    const { Flowpage_PageProfile_Exited_ReminderBanner } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Exited_ReminderBanner, {
      bannerColor,
      bannerCta
    })
  },
  Flowpage_PageProfile_Clicked_EditPageProfileIcon(): void {
    const { Flowpage_PageProfile_Clicked_EditPageProfileIcon } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_EditPageProfileIcon)
  },
  Flowpage_PageProfile_Clicked_EditBioImageButton(): void {
    const { Flowpage_PageProfile_Clicked_EditBioImageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_EditBioImageButton)
  },
  Flowpage_PageProfile_Clicked_RemoveBioImageButton(): void {
    const { Flowpage_PageProfile_Clicked_RemoveBioImageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_RemoveBioImageButton)
  },
  Flowpage_PageProfile_Clicked_AddBioImageButton(): void {
    const { Flowpage_PageProfile_Clicked_AddBioImageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_AddBioImageButton)
  },
  Flowpage_PageProfile_Typed_Name(): void {
    const { Flowpage_PageProfile_Typed_Name } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Typed_Name)
  },
  Flowpage_PageProfile_Typed_Bio(): void {
    const { Flowpage_PageProfile_Typed_Bio } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Typed_Bio)
  },
  Flowpage_PageProfile_Clicked_PreviewFlowpageButton(deviceType: 'mobile' | 'desktop'): void {
    const { Flowpage_PageProfile_Clicked_PreviewFlowpageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_PreviewFlowpageButton, {
      deviceType
    })
  },
  Flowpage_TemplateEditor_Clicked_PreviewFlowpageButton(deviceType: 'mobile' | 'desktop'): void {
    const { Flowpage_TemplateEditor_Clicked_PreviewFlowpageButton } = eventNames
    fireHeapEvent(Flowpage_TemplateEditor_Clicked_PreviewFlowpageButton, {
      deviceType
    })
  },
  Flowpage_PageProfile_Selected_Color({
    colorName,
    colorHex,
    colorSelectedVia
  }: {
    colorName: string
    colorHex: string
    colorSelectedVia: string
  }): void {
    const { Flowpage_PageProfile_Selected_Color } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_Color, {
      colorName,
      colorHex,
      colorSelectedVia
    })
  },
  Flowpage_PageProfile_Selected_Font({
    font,
    deviceType
  }: {
    font: string
    deviceType: 'mobile' | 'desktop'
  }): void {
    const { Flowpage_PageProfile_Selected_Font } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_Font, {
      font,
      deviceType
    })
  },
  Flowpage_PageProfile_Selected_Shape(shape: string): void {
    const { Flowpage_PageProfile_Selected_Shape } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_Shape, {
      shape
    })
  },
  Flowpage_PageProfile_Selected_LinkStyle(linkStyle: string): void {
    const { Flowpage_PageProfile_Selected_LinkStyle } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_LinkStyle, {
      linkStyle
    })
  },
  Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton(): void {
    const { Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton)
  },
  Flowpage_PageProfile_Selected_UploadBackgroundImageButton(): void {
    const { Flowpage_PageProfile_Selected_UploadBackgroundImageButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_UploadBackgroundImageButton)
  },
  Flowpage_PageProfile_Added_BackgroundImage(): void {
    const { Flowpage_PageProfile_Added_BackgroundImage } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Added_BackgroundImage)
  },
  Flowpage_PageProfileAnalytics_Hovered_HelpIcon(tab: string): void {
    const { Flowpage_PageProfileAnalytics_Hovered_HelpIcon } = eventNames
    fireHeapEvent(Flowpage_PageProfileAnalytics_Hovered_HelpIcon, {
      tab
    })
  },
  Flowpage_PageProfile_Clicked_LeftNavigationBox(tab: string): void {
    const { Flowpage_PageProfile_Clicked_LeftNavigationBox } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_LeftNavigationBox, {
      tab
    })
  },
  Flowpage_PageProfile_Copied_FlowpageURL(): void {
    const { Flowpage_PageProfile_Copied_FlowpageURL } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Copied_FlowpageURL)
  },
  Flowpage_PageProfile_Exited_GatedFeatureUpsell(upsellFeature: string): void {
    const { Flowpage_PageProfile_Exited_GatedFeatureUpsell } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Exited_GatedFeatureUpsell, {
      upsellFeature
    })
  },
  Flowpage_PageProfile_Clicked_ContextMenuButton(): void {
    const { Flowpage_PageProfile_Clicked_ContextMenuButton } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_ContextMenuButton)
  },
  Flowpage_PageProfile_Clicked_ProUpsellHexColors(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_ProUpsellHexColors)
  },
  Flowpage_Permissions_Clicked_Desktop_Tab(): void {
    const { Flowpage_Permissions_Clicked_Desktop_Tab } = eventNames
    fireHeapEvent(Flowpage_Permissions_Clicked_Desktop_Tab)
  },
  Flowpage_PageManagement_Clicked_PermissionsButton(): void {
    const { Flowpage_PageManagement_Clicked_PermissionsButton } = eventNames
    fireHeapEvent(Flowpage_PageManagement_Clicked_PermissionsButton)
  },
  Flowpage_PageManagement_Clicked_CreatePageButton(): void {
    const { Flowpage_PageManagement_Clicked_CreatePageButton } = eventNames
    fireHeapEvent(Flowpage_PageManagement_Clicked_CreatePageButton)
  },
  Flowpage_PageManagement_Clicked_WatchDemoButton(): void {
    const { Flowpage_PageManagement_Clicked_WatchDemoButton } = eventNames
    fireHeapEvent(Flowpage_PageManagement_Clicked_WatchDemoButton)
  },
  Flowpage_PageManagement_Clicked_CloseWatchDemoButton(): void {
    const { Flowpage_PageManagement_Clicked_CloseWatchDemoButton } = eventNames
    fireHeapEvent(Flowpage_PageManagement_Clicked_CloseWatchDemoButton)
  },
  Flowpage_Mobile_Onboarding_Clicked_NextButton(variant?: string | null, page?: string): void {
    const { Flowpage_Mobile_Onboarding_Clicked_NextButton } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Clicked_NextButton, { variant, page })
  },
  Flowpage_Mobile_Onboarding_Clicked_PreviewButton(variant?: string | null, page?: string): void {
    const { Flowpage_Mobile_Onboarding_Clicked_PreviewButton } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Clicked_PreviewButton, { variant, page })
  },
  Flowpage_Mobile_Onboarding_PublishedPage(variant?: string | null): void {
    const { Flowpage_Mobile_Onboarding_PublishedPage } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_PublishedPage, { variant })
  },
  Flowpage_Mobile_Onboarding_Added_Destination(destination: string, variant?: string | null): void {
    const { Flowpage_Mobile_Onboarding_Added_Destination } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Added_Destination, { destination, variant })
  },
  Flowpage_Mobile_Onboarding_Entered_Email(): void {
    const { Flowpage_Mobile_Onboarding_Entered_Email } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Entered_Email)
  },
  Flowpage_Mobile_Onboarding_Entered_Password(): void {
    const { Flowpage_Mobile_Onboarding_Entered_Password } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Entered_Password)
  },
  Flowpage_Mobile_Onboarding_Clicked_View_Flowpage(): void {
    const { Flowpage_Mobile_Onboarding_Clicked_View_Flowpage } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Clicked_View_Flowpage)
  },
  Flowcode_PageManagement_Clicked_Flowpage_FAQs_Btn(): void {
    const { Flowcode_PageManagement_Clicked_Flowpage_FAQs_Btn } = eventNames
    fireHeapEvent(Flowcode_PageManagement_Clicked_Flowpage_FAQs_Btn)
  },
  Flowcode_PageProfile_Clicked_Font_Style_Lock(): void {
    const { Flowcode_PageProfile_Clicked_Font_Style_Lock } = eventNames
    fireHeapEvent(Flowcode_PageProfile_Clicked_Font_Style_Lock)
  },
  Flowpage_PageProfile_Clicked_Link_Level_Customization_Options(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Link_Level_Customization_Options)
  },
  Flowpage_PageProfile_Toggled_Link_Level_Advanced_Customization(value: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Toggled_Link_Level_Advanced_Customization, {
      value
    })
  },
  Flowpage_PageProfile_Selected_Link_Level_Link_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Link_Level_Link_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Link_Level_Shadow_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Link_Level_Shadow_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Link_Level_Border_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Link_Level_Border_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Link_Level_Text_Size(fontSize: number): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Link_Level_Text_Size, { fontSize })
  },
  Flowpage_PageProfile_Selected_Link_Level_Font_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Link_Level_Font_Color, { colorHex })
  },
  Flowpage_PageProfile_Clicked_Page_Level_Link_Colors_Lock(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Page_Level_Link_Colors_Lock)
  },
  Flowpage_PageProfile_Selected_Page_Level_Font_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Page_Level_Font_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Page_Level_Text_Size(fontSize: number): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Page_Level_Text_Size, { fontSize })
  },
  Flowpage_PageProfile_Selected_Page_Level_Link_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Page_Level_Link_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Page_Level_Border_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Page_Level_Border_Color, { colorHex })
  },
  Flowpage_PageProfile_Selected_Page_Level_Shadow_Color(colorHex: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Selected_Page_Level_Shadow_Color, { colorHex })
  },
  Flowpage_PageProfile_Clicked_AddLink(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_AddLink)
  },
  Flowpage_PageProfile_Clicked_ExploreLinks(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_ExploreLinks)
  },
  Flowpage_PageProfile_Clicked_Permissions(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Permissions)
  },
  Flowpage_PageProfile_Clicked_Duplicate(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Duplicate)
  },
  Flowpage_PageProfile_Clicked_Analytics(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Analytics)
  },
  Flowpage_PageProfile_Clicked_Share(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Share)
  },
  Flowpage_PageProfile_ExploreLinksModal_Clicked_Dismiss_MostPopularLinksSection(): void {
    fireHeapEvent(
      eventNames.Flowpage_PageProfile_ExploreLinksModal_Clicked_Dismiss_MostPopularLinksSection
    )
  },
  Flowpage_PageProfile_ExploreLinksModal_Closed_Modal(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_ExploreLinksModal_Closed_Modal)
  },
  Flowpage_PageProfile_ExploreLinksModal_Clicked_AddLink({ linkType }: { linkType: string }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_ExploreLinksModal_Clicked_AddLink, { linkType })
  },
  Flowpage_PageProfile_DuplicateOverlay_Closed_Overlay(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Closed_Overlay)
  },
  Flowpage_PageProfile_DuplicateOverlay_Changed_DuplicateCount({
    count,
    changeType
  }: {
    count: number
    changeType: 'plus' | 'minus'
  }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Changed_DuplicateCount, {
      count,
      changeType
    })
  },
  Flowpage_PageProfile_DuplicateOverlay_Clicked_MakeDuplicate({ count }: { count: number }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Clicked_MakeDuplicate, {
      count
    })
  },
  Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToDuplicatePage(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToDuplicatePage)
  },
  Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToPages(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Clicked_GoToPages)
  },
  Flowpage_PageProfile_DuplicateOverlay_Clicked_DuplicateMore(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_DuplicateOverlay_Clicked_DuplicateMore)
  },
  Flowpage_PageProfile_Clicked_CancelLinkEdits(): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_CancelLinkEdits)
  },
  Flowpage_PageProfile_Clicked_HideLink({
    linkId,
    actionType
  }: {
    linkId: string
    actionType: 'hide' | 'unhide'
  }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_HideLink, { linkId, actionType })
  },
  Flowpage_PageProfile_StandardLinkForm_Typed_LinkUrl({
    linkId,
    typedUrl
  }: {
    linkId: string
    typedUrl: string
  }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_StandardLinkForm_Typed_LinkUrl, {
      linkId,
      typedUrl
    })
  },
  Flowpage_Mobile_Onboarding_ContactCollection_Added(variant?: string | null, page?: string): void {
    const { Flowpage_Mobile_Onboarding_ContactCollection_Added } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_ContactCollection_Added, { variant, page })
  },
  Flowpage_Mobile_Onboarding_Toggled_Include_CRM(
    variant?: string | null,
    page?: string,
    toggledContactCollection?: string
  ): void {
    const { Flowpage_Mobile_Onboarding_Toggled_Include_CRM } = eventNames
    fireHeapEvent(Flowpage_Mobile_Onboarding_Toggled_Include_CRM, {
      variant,
      page,
      toggledContactCollection
    })
  },
  Flowpage_Clicked_Suggested_Link_URL(): void {
    const { Flowpage_Clicked_Suggested_Link_URL } = eventNames
    fireHeapEvent(Flowpage_Clicked_Suggested_Link_URL)
  },
  Flowpage_Management_Typed_Slugname(args: { isUnifiedTemplates: boolean }): void {
    const { Flowpage_Management_Typed_Slugname } = eventNames
    fireHeapEvent(Flowpage_Management_Typed_Slugname, args)
  },
  Flowpage_Management_Clicked_Get_Started_Button(args: { isUnifiedTemplates: boolean }): void {
    const { Flowpage_Management_Clicked_Get_Started_Button } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Get_Started_Button, args)
  },
  Flowpage_PageProfile_Clicked_Image_Display_Type(): void {
    const { Flowpage_PageProfile_Clicked_Image_Display_Type } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Image_Display_Type)
  },
  Flowpage_PageProfile_Typed_Link_Title(): void {
    const { Flowpage_PageProfile_Typed_Link_Title } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Typed_Link_Title)
  },
  Flowpage_PageProfile_Typed_Link_Description(): void {
    const { Flowpage_PageProfile_Typed_Link_Description } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Typed_Link_Description)
  },
  Flowpage_PageProfile_Clicked_Add_Icon_Image(): void {
    const { Flowpage_PageProfile_Clicked_Add_Icon_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Add_Icon_Image)
  },
  Flowpage_PageProfile_Clicked_Icon_Image_Reposition(): void {
    const { Flowpage_PageProfile_Clicked_Icon_Image_Reposition } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Icon_Image_Reposition)
  },
  Flowpage_PageProfile_Clicked_Icon_Image_Zoom(): void {
    const { Flowpage_PageProfile_Clicked_Icon_Image_Zoom } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Icon_Image_Zoom)
  },
  Flowpage_PageProfile_Clicked_Change_Icon_Image(): void {
    const { Flowpage_PageProfile_Clicked_Change_Icon_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Change_Icon_Image)
  },
  Flowpage_PageProfile_Clicked_Save_Icon_Image(): void {
    const { Flowpage_PageProfile_Clicked_Save_Icon_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Save_Icon_Image)
  },
  Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window(): void {
    const { Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window)
  },
  Flowpage_PageProfile_Clicked_Replace_Icon_Image(): void {
    const { Flowpage_PageProfile_Clicked_Replace_Icon_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Replace_Icon_Image)
  },
  Flowpage_PageProfile_Clicked_Delete_Icon_Image(): void {
    const { Flowpage_PageProfile_Clicked_Delete_Icon_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Delete_Icon_Image)
  },
  Flowpage_PageProfile_Selected_Link_Level_Fill_Color(): void {
    const { Flowpage_PageProfile_Selected_Link_Level_Fill_Color } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Selected_Link_Level_Fill_Color)
  },
  Flowpage_PageProfile_Clicked_Edit_Link(): void {
    const { Flowpage_PageProfile_Clicked_Edit_Link } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Edit_Link)
  },
  Flowpage_PageProfile_Clicked_Delete_Link(): void {
    const { Flowpage_PageProfile_Clicked_Delete_Link } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Delete_Link)
  },
  Flowpage_PageProfile_Clicked_Cancel_Delete_Link(): void {
    const { Flowpage_PageProfile_Clicked_Cancel_Delete_Link } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Cancel_Delete_Link)
  },
  Flowpage_PageProfile_Clicked_Profile_Image_Reposition(): void {
    const { Flowpage_PageProfile_Clicked_Profile_Image_Reposition } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Profile_Image_Reposition)
  },
  Flowpage_PageProfile_Clicked_Profile_Image_Zoom(): void {
    const { Flowpage_PageProfile_Clicked_Profile_Image_Zoom } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Profile_Image_Zoom)
  },
  Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape(): void {
    const { Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape)
  },
  Flowpage_PageProfile_Clicked_Delete_Profile_Image(): void {
    const { Flowpage_PageProfile_Clicked_Delete_Profile_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Delete_Profile_Image)
  },
  Flowpage_PageProfile_Clicked_Change_Profile_Image(): void {
    const { Flowpage_PageProfile_Clicked_Change_Profile_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Change_Profile_Image)
  },
  Flowpage_PageProfile_Clicked_Save_Profile_Image(): void {
    const { Flowpage_PageProfile_Clicked_Save_Profile_Image } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Save_Profile_Image)
  },
  Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window(): void {
    const { Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window)
  },
  Flowpage_Management_Clicked_Create_Page_button(): void {
    const { Flowpage_Management_Clicked_Create_Page_button } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Create_Page_button)
  },
  Flowpage_Management_Clicked_Sort_Option(sortOption: string): void {
    const { Flowpage_Management_Clicked_Sort_Option } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Sort_Option, {
      sortOption
    })
  },
  Flowpage_Management_Clicked_Sort_Button(): void {
    const { Flowpage_Management_Clicked_Sort_Button } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Sort_Button)
  },
  Flowpage_Management_Clicked_Sort_Direction_Button(direction: string): void {
    const { Flowpage_Management_Clicked_Sort_Direction_Button } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Sort_Direction_Button, {
      direction
    })
  },
  Flowpage_Management_Clicked_Grid_List_Toggle(view: SelectedFlowpageView): void {
    const { Flowpage_Management_Clicked_Grid_List_Toggle } = eventNames
    fireHeapEvent(Flowpage_Management_Clicked_Grid_List_Toggle, {
      view
    })
  },
  Flowpage_PageProfile_Clicked_Reset_LinkLevelStyling(): void {
    const { Flowpage_PageProfile_Clicked_Reset_LinkLevelStyling } = eventNames
    fireHeapEvent(Flowpage_PageProfile_Clicked_Reset_LinkLevelStyling)
  },
  Flowpage_PageProfile_Clicked_Publish_Flowpage({
    isAuthenticated,
    slugName,
    mobile = false
  }: {
    isAuthenticated: boolean
    slugName?: string
    mobile?: boolean
  }): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_Clicked_Publish_Flowpage, {
      isAuthenticated,
      slugName,
      mobile: getHeapBooleanParam(mobile)
    })
  },
  Flowpage_TemplateEditor_Clicked_Assign(): void {
    const { Flowpage_TemplateEditor_Clicked_Assign } = eventNames
    fireHeapEvent(Flowpage_TemplateEditor_Clicked_Assign)
  },
  Flowpage_Management_Clicked_ChangeSubdomain(domainId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_ChangeSubdomain, {
      domainId
    })
  },
  Flowpage_Management_Clicked_Share_Modal_Flowpage_Link(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_Share_Modal_Flowpage_Link)
  },
  Flowpage_Management_Clicked_Share_Modal_Copy_Url_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_Share_Modal_Copy_Url_Button)
  },
  Flowpage_Management_Clicked_Share_Modal_Start_Connecting_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_Share_Modal_Start_Connecting_Button)
  },
  Flowpage_Management_Clicked_Share_Modal_Flowtag_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_Share_Modal_Flowtag_Button)
  },
  Flowpage_Management_Clicked_Share_Modal_View_Flowcode_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_Share_Modal_View_Flowcode_Button)
  },
  Flowpage_Homepage_Clicked_Create_Flowcode_Button(): void {
    fireHeapEvent(eventNames.Flowpage_Homepage_Clicked_Create_Flowcode_Button)
  },
  Flowpage_PageProfile_RichTextEditor_Clicked_FormattingOption(formatOption: string): void {
    fireHeapEvent(eventNames.Flowpage_PageProfile_RichTextEditor_Clicked_FormattingOption, {
      formatOption
    })
  },
  FlowpageManagement_ShareAssetsModal_Shared_Flowpage(pageId: string): void {
    fireAnalyticsEvent(
      eventNames.FlowpageManagement_ShareAssetsModal_Shared_Flowpage,
      {
        pageId
      },
      [AnalyticsIntegrations.HEAP, AnalyticsIntegrations.INTERCOM]
    )
  },
  Flowpage_PageManagement_Clicked_CreateTemplateButton(args: { pageId: string }): void {
    const { Flowpage_PageManagement_Clicked_CreateTemplateButton } = eventNames
    fireHeapEvent(Flowpage_PageManagement_Clicked_CreateTemplateButton, args)
  },
  Flowpage_Settings_Clicked_PixelToggle_passthrough(isFlowpage: boolean, value: boolean): void {
    fireAnalyticsEvent(eventNames.Flowpage_Settings_Clicked_PixelToggle_passthrough, {
      product: getProduct(isFlowpage),
      value
    })
  },
  Flowpage_Settings_Hovered_PixelTooltip_passthrough(isFlowpage: boolean, label: string): void {
    fireAnalyticsEvent(eventNames.Flowpage_Settings_Hovered_PixelTooltip_passthrough, {
      product: getProduct(isFlowpage),
      displaytext: label
    })
  }
}

export default flowpage
